import React from 'react'
import TopRoutesGrid from './TopRoutesGrid'
import coim from '../../assets/images/coim.jpeg'
import salem from "../../assets/images/salem.jpeg";
import trichy from "../../assets/images/trichy.jpeg";
import pondy from "../../assets/images/pondy.jpeg";
import bng from "../../assets/images/bangalore.jpeg";
import madurai from "../../assets/images/madurai.jpg";
import tiruvannamalai from "../../assets/images/thiruvannamalai.jpeg";
import tirupathi from "../../assets/images/tirupathi.jpeg";
import ooty from "../../assets/images/ooty.jpeg";
import chennai from "../../assets/images/chennai.jpeg";
import dharmapuri from "../../assets/images/dharmapuru.jpeg";
import pollachi from "../../assets/images/pollachi.jpeg";
import sedan from "../../assets/images/cars/swift.png";
import  primeSuv from "../../assets/images/cars/ToyatoInnovaPrime.png";
import  xylo from "../../assets/images/cars/xyloSuv.png"
const TopRoutes = () => {
    return (
        <div className='container mx-auto mt-5 px-5' id='vehicle'>
    <h4 className=' text-lg font-medium primary-color text-center'>OUR VEHICLE</h4>

<h1 className="text-center font-bold text-5xl ">RIDE WITH EXPERTS</h1>
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-5 p-4'>
                <TopRoutesGrid grid_image={sedan} heading="Swift Dzire" car="SEDAN" />
                <TopRoutesGrid grid_image={primeSuv} heading="Toyato Innova" car="PRIME SUV" width="w-[450px]"/>
                <TopRoutesGrid grid_image={xylo} heading="MAHINDRA XYLO" car="SUV" />

            </div>
        </div>
    )
}

export default TopRoutes