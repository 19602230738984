import React from 'react'
import about from "../assets/aboutgif.gif";
import { FaCheck } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import airport from "../assets/images/airport transfer-covaicalltaxi.jpg";
import time from "../assets/images/24X7taxiservice.jpg";
import localrentals from "../assets/images/local rentals-covaicalltaxi.jpg";
import outstation from "../assets/images/outstation trips-covaicalltaxi.jpg"

const About = () => {
    return (
        <div id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-14 gap-x-20 items-center'>
                    <div className='  '>
                                   <div>
                                   < img src={about} alt="about" className="" />
   
                                   </div>
                            {/* < img src={localrentals} alt="about" className="" />
                            < img src={airport} alt="about" className="" />
                            < img src={time} alt="about" className="" />
                            < img src={outstation} alt="about" className="" />

                            <div className=' absolute bottom-2 sm: bottom-[-70px] right-[100px] md:bottom-[10px] right-1 translate-x-1/2 md:translate-x-0 md:right-[60px]  bg-white py-4 px-4 rounded-xl shadow-md'>
                                <div className=' flex flex-col gap-y-1 items-center'>
                                    <span className='primary-color text-4xl font-bold'>6+</span>
                                    <span className=' text-lg opacity-60 font-medium'>Years Experience</span>
                                </div>

                            </div> */}
                    </div>
                    <div className='mt-20 md:mt-0  w-full '>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-1xl md:text-3xl font-medium mt-4'>
                        Welcome to Fly Drop Taxi Service
                        </div>
                        {/* <div className='text-md md:text-lg font-semibold mt-4 opacity-80'>
                        When it comes to taxi services in South India, Fly Drop Taxi is among the best. We value your time, safety, and comfort above anything else. We are a full-service travel agency that operates throughout South India, including taxis, outstation cabs, airport transfers, drop-off services, vehicle rentals, sightseeing tours, and more. With this closeness, there's no reason to wait. Quickly act. You can trust our courteous and knowledgeable drivers to get you to your destination safely and quickly. Rapidly call for a taxi service.

</div> */}

                        <div className="mt-10">
                            <p>
                            When it comes to taxi services in South India, FLY DROP TAXI is among the best. We value your time, safety, and comfort above anything else. We offer car hire services with seating capacity ranging from 4 to 40 passengers. Not only this, but we also provide both one way outstation cabs as well as round trip taxi rental options. You can also book a cab for hourly rental, airport transfers, corporate travel, employee transportation, weekend, honeymoon package tours and much more. Rapidly call for a taxi service.
                             </p>
{/* <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> 24/7 Support</span>
                          <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> Best Price</span>
                          <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50'/> Experienced Drivers</span> */}

                            {/* <h3 className='text-1xl mt-4 font-medium'> ONE WAY TAXI</h3> */}
                            {/* <p className='mt-1 pl-4'>No Return fare! Why need to pay more for one way Taxi, We're the most trusted one.

                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> ROUND TRIP
                            </h3>
                            <p className='mt-1 pl-4'>Your pick-up address can be anywhere in pick-up city and drop address can be.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> AIRPORT PICKUP
                            </h3>
                            <p className='mt-1 pl-4'>Be it welcoming your friend at the airport right on time or any emergency situation.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> OUTSTATION CAB
                            </h3>
                            <p className='mt-1 pl-4'>Our Outstation taxi are the best for short and long trips to spend quality time with.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> DROP TAXI
                            </h3>
                            <p className='mt-1 pl-4'>Book a Oneway drop taxi to take you to your destination in the town, anywhere, anytime.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> 100% SAFETY
                            </h3>
                            <p className='mt-1 pl-4'>Verified drivers, an emergency alert button, and live ride tracking
                            </p> */}
                        </div>
                        {/* <p className='text-black opacity-60 md:text-lg mt-5'>We're in this business Since 2018 and we provide the best services
               </p> */}
                        {/* <ul className='about-ul'>
                            <li className='about-li'>Easy Taxi booking in minutes</li>
                            <li className='about-li'>The best and transparent Pricing in the Market</li>
                            <li className='about-li'>Experienced skilled Drivers.

                            </li>

                        </ul> */}
                        <div className='flex  flex-col md-flex-row lg:flex-row xl:flex-row mt-5 gap-2 items-center '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+917540057878 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 7540057878</span>
                              </a>
                         </div>
                      </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default About