import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FaCar } from "react-icons/fa";

import { FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules';
import bg from "../../assets/images/taxi_bg_1.jpg";
import bg1 from "../../assets/images/pattern_bg_5.jpg";
import madurai from "../../assets/places/maduraiscrool.jpg";
import trichy from "../../assets/places/trichyScroll.jpg";
import coimbatore from "../../assets/places/coimbatoreScroll.jpg";
import banglore from "../../assets/places/bangaloreScroll.jpg";
import salem from "../../assets/places/salemScroll.jpg";
import chennai from "../../assets/places/chennaiScroll.jpg"

const Vision = () => {
  const divStyle2={
    backgroundImage: `url( ${bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat:"no-repeat"

  }

  return (
    <div className='flex items-center justify-center flex-col p-5 gap-y-3 md:p-16 lg:p-20 ' id='cities'>
    <h4 className=' text-lg font-medium primary-color text-center'>CITIES</h4>

<h1 className="text-center font-bold text-5xl ">CITIES</h1>
<Swiper
loop={true}         
autoplay={{
delay: 3000, 
disableOnInteraction: false // Autoplay will not be disabled after user interactions
}}
breakpoints={{
340: {
slidesPerView: 1,
spaceBetween: 15
},
700: {
slidesPerView: 2,
spaceBetween: 15
}
}}
freeMode={true}
// pagination={{
//   clickable: true
// }}
// navigation={{
//   nextEl: '.swiper-button-next',
//   prevEl: '.swiper-button-prev',
// }} 
modules={[FreeMode, Pagination, Navigation, Autoplay]}
className='max-w-[100%] lg:max-w-[100%] p-2 lg:mt-20'
>
<SwiperSlide className='mb-20 '>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={madurai} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Madurai,TamilNadu</strong>
</div>
</SwiperSlide>
<SwiperSlide className='mb-20'>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={trichy} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Trichy,TamilNadu</strong>
</div>

</SwiperSlide>
<SwiperSlide className='mb-20'>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={salem} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Salem,TamilNadu</strong>
</div>

</SwiperSlide>
<SwiperSlide className='mb-20'>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={banglore} alt="Madurai" className='hover:scale-110 transition ease-in contain w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Banglore,TamilNadu</strong>
</div>

</SwiperSlide>
<SwiperSlide className='mb-20'>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={chennai} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Chennai,TamilNadu</strong>
</div>

</SwiperSlide>

<SwiperSlide className='mb-20'>
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={coimbatore} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'>VISIT CITY</div>
<strong className="text-4xl">Coimbatore,TamilNadu</strong>
</div>

</SwiperSlide>


{/* Navigation buttons */}
{/* <div className="swiper-button-prev"></div>
<div className="swiper-button-next"></div> */}
</Swiper>
</div>

  )
}

export default Vision
